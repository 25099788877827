<template>
  <div>
    <template v-if="this.$store.state.settings.resep.enable === 'enable' || this.$store.state.settings.gizi">
      <v-container v-for="(itemx, x) in obat" :key="x">
        <div v-for="(item, y) in label" :key="y">
          <v-row class="berkas align-center grey lighten-3 mb-3" v-if="itemx && itemx.obat && item.content.page.type === type && item.content.page.etiket == itemx.tipe">
            <v-col cols="3" sm="2">
              <v-text-field
                type="number"
                name="jumlah"
                label="JML"
                class="text-center"
                outlined
                :value="item.content.page.jumlah"
                dense
                flat
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                style="display:none;"
                type="hidden"
                name="enablex"
                :value="x"
              ></v-text-field>
              <input name="urlToPrint" :value="url + '/label/' + item._id + '/' + pasien + '?resep_id=' + data.id + '&obat_id=' + itemx.id + '&waktu=' + itemx.waktu + '&udd=' + $store.state.settings.resep?.udd_by + '&gizi=' + $store.state.settings.gizi + '&ke=' + itemx.ke" type="hidden" />
              <input name="landscape" :value="item.content.page.printMode === 'landscape' ? true : false" type="hidden" />
              <v-text-field
                style="display:none;"
                type="hidden"
                name="selectedPrinter"
                :value="item.content.page.printer"
              ></v-text-field>
              <input name="paper" :value="JSON.stringify({height:item.content.page.height*1000, width: item.content.page.width*1000})" type="hidden" />
            </v-col>
            <v-col cols="6" sm="8" class="py-2 text-uppercase">
              {{itemx.obat}}<br>
              <span class="is-hidden-mobile text-normal nama-label">{{`${item.name} (${item.content.page.printer})`}}</span>
            </v-col>
            <v-col cols="3" sm="2" class="justify-end">
              <v-switch class="mt-0" color="#00cccc" name="enable" dense inset :value="enable[x]" :input-value="enable[x]" @change="change(x)"></v-switch>
            </v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="text-body-1 px-3">
                  <div class="col-10 pa-0">Preview</div>
                  <v-avatar
                    size="20"
                    tile
                    class="col-2 pa-0 justify-end"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-avatar>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-2 mb-2" style="background:#fff;margin: auto;border:1px solid #000;border-radius:5px;width:fit-content">
                    <iframe frameBorder="0" class="embed-responsive-item" :src="url + '/label/' + item._id + '/' + pasien + '?resep_id=' + data.id + '&obat_id=' + itemx.id + '&waktu=' + itemx.waktu + '&udd=' + $store.state.settings.resep?.udd_by + '&gizi=' + $store.state.settings.gizi + '&ke=' + itemx.ke" scrolling="no" :style="`
                    width: ${item.content.page.width}mm;
                    height: ${item.content.page.height}mm;`"/>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-container>
    </template>
    <template v-else-if="this.$store.state.settings.defaultData !== 'pasien'">
      <template v-for="(item, y) in printData">
        <v-container v-if="item.content.page.type === type" :key="y">
          <v-row class="berkas align-center grey lighten-3 mb-3">
            <v-col cols="3" sm="2">
              <v-text-field
                type="number"
                name="jumlah"
                label="JML"
                class="text-center"
                outlined
                :value="item.content.page.jumlah"
                dense
                flat
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                style="display:none;"
                type="hidden"
                name="enablex"
                :value="y"
              ></v-text-field>
              <input name="urlToPrint" :value="url + '/label/' + item._id + '/' + pasien" type="hidden" />
              <input name="landscape" :value="item.content.page.printMode === 'landscape' ? true : false" type="hidden" />
              <input name="paper" :value="JSON.stringify({height:item.content.page.height*1000, width: item.content.page.width*1000})" type="hidden" />
              <v-text-field
                style="display:none;"
                type="hidden"
                name="selectedPrinter"
                :value="item.content.page.printer"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="8" class="py-2 text-uppercase">
              <span class="is-hidden-mobile text-normal nama-label">{{`${item.name} (${item.content.page.printer})`}}</span>
            </v-col>
            <v-col cols="3" sm="2" class="justify-end">
              <v-switch class="mt-0" color="#00cccc" name="enable" dense inset :value="enable[y]" :input-value="enable[y]" @change="change(y)"></v-switch>
            </v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="text-body-1 px-3">
                  <div class="col-10 pa-0">Preview</div>
                  <v-avatar
                    size="20"
                    tile
                    class="col-2 pa-0 justify-end"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-avatar>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-2 mb-2" style="background:#fff;margin: auto;border:1px solid #000;border-radius:5px;width:fit-content">
                    <iframe frameBorder="0" class="embed-responsive-item" :src="url + '/label/' + item._id + '/' + pasien" scrolling="no" :style="`
                    width: ${item.content.page.width}mm;
                    height: ${item.content.page.height}mm;`"/>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </template>
    </template>
    <template v-else>
      <template v-for="(item, y) in printData">
        <v-container v-if="item.content.page.type === type || item.content.page.type === 'pasien'" :key="y">
          <v-row class="berkas align-center grey lighten-3 mb-3">
            <v-col cols="3" sm="2">
              <v-text-field
                type="number"
                name="jumlah"
                label="JML"
                class="text-center"
                outlined
                :value="item.content.page.jumlah"
                dense
                flat
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                style="display:none;"
                type="hidden"
                name="enablex"
                :value="y"
              ></v-text-field>
              <input name="urlToPrint" :value="url + '/label/' + item._id + '/' + pasien" type="hidden" />
              <input name="landscape" :value="item.content.page.printMode === 'landscape' ? true : false" type="hidden" />
              <input name="paper" :value="JSON.stringify({height:item.content.page.height*1000, width: item.content.page.width*1000})" type="hidden" />
              <v-text-field
                style="display:none;"
                type="hidden"
                name="selectedPrinter"
                :value="item.content.page.printer"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="8" class="py-2 text-uppercase">
              <span class="is-hidden-mobile text-normal nama-label">{{`${item.name} (${item.content.page.printer})`}}</span>
            </v-col>
            <v-col cols="3" sm="2" class="justify-end">
              <v-switch class="mt-0" color="#00cccc" name="enable" dense inset :value="enable[y]" :input-value="enable[y]" @change="change(y)"></v-switch>
            </v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="text-body-1 px-3">
                  <div class="col-10 pa-0">Preview</div>
                  <v-avatar
                    size="20"
                    tile
                    class="col-2 pa-0 justify-end"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-avatar>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-2 mb-2" style="background:#fff;margin: auto;border:1px solid #000;border-radius:5px;width:fit-content">
                    <iframe frameBorder="0" class="embed-responsive-item" :src="url + '/label/' + item._id + '/' + pasien" scrolling="no" :style="`
                    width: ${item.content.page.width}mm;
                    height: ${item.content.page.height}mm;`"/>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "PrintData",
  props: {
    type: String,
    etiket: String,
    pasien: [String, Number],
    data: Object,
    unit: String,
    list: Array,
    custom: Boolean
  },
  data() {
    return {
      label: [],
      enable: [],
      url: this.$store.state.settings.server_url || "http://localhost:3030",
      printUrl: (this.$store.state.settings.print_server ? "http://localhost:3030" : this.$store.state.settings.server_url) || "http://localhost:3030",
    };
  },
  created() {
    this.getData()
    this.enableAll()
  },
  computed: {
    printData() {
      let data = this.label;
      if(this.custom) {
        data = data.filter(x => x.name.toLowerCase() === this.$store.state.settings.custom.label.toLowerCase());
      } else {
        data = data.filter(x => {
          if(this.$store.state.settings.custom.enable) {
            return x.name.toLowerCase() !== this.$store.state.settings.custom.label.toLowerCase();
          } else {
            return this.label;
          }
        })
      }
      return data;
    },
    obat() {
      let obat = [];

      this.data.obat && this.data.obat.forEach((item) => {
        if (!item.tipe.includes('udd') && !(this.$store.state.settings.gizi && item.tipe === 'racikan_biru')) obat.push(item);
        if(this.$store.state.settings.gizi && item.tipe === 'racikan_biru') {
          let one = {...item}
          one.jam = item.waktu1
          one.ke = item.ke1
          obat.push(one)

          let two = {...item}
          two.jam = item.waktu2
          two.ke = item.ke2
          obat.push(two)
          // console.log('ee');
        }
      });
      const groupBy = (x,f)=>x.reduce((a,b)=>((a[f(b)]||=[]).push(b),a),{});
      // obat.push(this.data.obat && this.data.obat.find((item) => item.tipe === "udd"));
      // obat.push(this.data.obat && this.data.obat.find((item) => item.tipe === "udd_biru"));
      // console.log(groupBy(this.data.obat.filter(z => z.tipe === 'udd'), v => v.waktu));
      if(this.data.obat?.length) {
        let xxx = this.data.obat.filter(z => z.tipe === 'udd')
        let waktu = xxx.map(item => item.waktu_udd);
        let obatx = []
        if(waktu) {
          let aaa = waktu.map((item, i) => {
          	let zzz = item && item.map((x,z) => {
              return x && x.dosis && x.dosis !== '0' && x
            })
            return zzz && zzz.filter(x => x)
          })

          obatx = aaa && aaa.flatMap((x,z) => {
          	let b = x && x.flatMap((c,d) => {
            	let obat = {}
              Object.assign(obat, xxx[z])
              obat.obat = 'UDD MINUM ' + c.waktu
              obat.waktu = c.waktu
              obat.qty = c.dosis
              return obat
            })
            return b && b.filter(x => x.waktu)
          })
        }
        let udd = {};
        if(this.$store.state.settings.resep?.udd_by === 'default') {
          obat.push(xxx[0]);
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu') {
          udd = groupBy(xxx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu_split') {
          udd = groupBy(obatx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        }
      }
      if(this.data.obat?.length) {
        let xxx = this.data.obat.filter(z => z.tipe === 'udd_biru')
        let waktu = xxx.map(item => item.waktu_udd);
        let obatx = []
        if(waktu) {
          let aaa = waktu.map((item, i) => {
          	let zzz = item && item.map((x,z) => {
              return x && x.dosis && x.dosis !== '0' && x
            })
            return zzz && zzz.filter(x => x)
          })

          obatx = aaa && aaa.flatMap((x,z) => {
          	let b = x && x.flatMap((c,d) => {
            	let obat = {}
              Object.assign(obat, xxx[z])
              obat.obat = 'UDD BIRU / INJEKSI ' + c.waktu
              obat.waktu = c.waktu
              obat.qty = c.dosis
              return obat
            })
            return b && b.filter(x => x.waktu)
          })
        }
        let udd = {};
        if(this.$store.state.settings.resep?.udd_by === 'default') {
          obat.push(xxx[0]);
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu') {
          udd = groupBy(xxx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu_split') {
          udd = groupBy(obatx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        }
      }
      console.log(obat.filter(item => item));
      
      return obat.filter(item => item)
    }
  },
  methods: {
    enableAll() {
      this.enable = new Array(9999)
      this.enable.fill(true)
      this.enable.map(item => item = true)
    },
    changeAll(enable) {
      let enablex = this.enable.map((item) => item = enable)
      this.enable = enablex
    },
    change(x){
      (this.enable[x]) ? this.enable[x] = false : this.enable[x] = true
      console.log(this.enable[x])
    },
    getData() {
      this.axios
        .get(this.url + "/api/label?content.page.status=enable")
        .then(r => {
          if (r.data) {
            this.label = r.data;
            //this.label = r.data.data
            // console.log(this.label)
          }
        })
        .catch(err => {
          console.log(err.message);
        });
    },
    doPrint() {
      let url = this.printUrl + "/print";
      var berkas = document.querySelectorAll(".berkas");
      var jobs = [];
      for (var x = 0; x < berkas.length; x++) {
        if(document.querySelectorAll("input[name=enablex]")[x] && this.enable[document.querySelectorAll("input[name=enablex]")[x].value] == true) {
          jobs.push({
            url: document.querySelectorAll("input[name=urlToPrint]")[x].value,
            printer: document.querySelectorAll("input[name=selectedPrinter]")[x]
              .value,
            settings: {
              copies: document.querySelectorAll("input[name=jumlah]")[x].value,
              landscape: document.querySelectorAll("input[name=landscape]")[x].value,
              paper: document.querySelectorAll("input[name=paper]")[x].value
            }
          });
          let label = document.getElementsByClassName("nama-label")[x].innerHTML;
          this.trackEvent(
            this.$store.state.settings.app_name,
            "print",
            label,
            document.querySelectorAll("input[name=jumlah]")[x].value
          );
        }
      }
      console.log(jobs);
      if (jobs.length) {
        this.axios
          .post(url, { jobs: jobs, portable: this.$store.state.settings.print_portable || false })
          .then(r => {
            // console.log(r.data);
            if(this.$store.state.settings.print_portable) {
              window.location.href = this.url + r.data;
            }
          })
          .catch(err => {
            console.log(err.message);
          });
      }
    }
  }
};
</script>
