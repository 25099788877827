<template>
  <v-dialog v-model="dialog" scrollable max-width="500" @keydown.esc="cancel()" @click:outside="cancel()">
    <v-card tile class="white">
      <v-card-title class="h5 justify-space-between">
        {{title}}
        <v-switch class="mt-0" color="#00cccc" name="enable" dense inset v-model="enable" @change="change()"></v-switch>
      </v-card-title>
      <v-card-text class="pb-0">
        <PrintData v-if="dialog" :custom="custom" ref="printData" :type="type" :pasien="pasien" :data="item" :unit="unit" :list="array"/>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          depressed
          small
          :loading="isLoading"
          color="#00cccc"
          class="white--text col"
          @click="accept"
        >
          Print
        </v-btn>
        <v-btn
          v-if="unit !== 'steril'"
          depressed
          small
          :loading="isLoading"
          color="grey"
          class="white--text col"
          @click="cancel"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from '@/components/InputField'
import PrintData from '@/components/PrintData'
export default {
  name: 'ModalPrint',
  components: { InputField, PrintData },
  props: {
      title: { type: String, default: 'CETAK ETIKET' },
      type: String,
      unit: String,
  },
  data () {
    return {
      qty: 1,
      item: {},
      pasien: null,
      isLoading: false,
      dialog: false,
      id: null,
      enable: true,
      resolve : null,
      reject : null,
      array: [],
      custom: false
    }
  },
  methods: {
    open(pasien, data, array, custom){
      this.pasien = pasien
      this.item = data
      this.array = array
      this.enable = true
      this.custom = custom
      
      // console.log(this.item)
      return new Promise((resolve, reject)=>{
        this.resolve = resolve
        this.reject = reject
        this.dialog = true
      })
    },
    change() {
      this.$refs.printData.changeAll(this.enable)
    },
    accept() {
      this.$refs.printData.doPrint()
      this.item = {}
      this.resolve(this.item);
      this.dialog = false;
    },
    cancel() {
      this.item = {}
      this.reject(this.item);
      this.dialog = false;
    },
  },
  watch: {
    dialog(value) {
      if(value)
        this.$refs.printData && this.$refs.printData.changeAll(this.enable)
    }
  },
}
</script>
